<template>
	<main class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>회원가입</li>
						</ul>
					</div>
					<h2 class="page_title">회원가입</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="join_visual">
						<strong class="tit"><b>WAPU</b>에 참여하세요.</strong>
						<p class="desc">
							잠들어있는 데이터를 분석하여<br class="br_575"> 인사이트를 제공해드리며<br>
							모든 서비스는 구글 계정을 통해서 진행되기에<br class="br_991"> <b>반드시 구글 계정으로 가입</b>을 부탁드립니다.
						</p>
					</div>
					<div class="join_agree">
						<ul class="agree_list">
							<li class="agree_all">
								<div class="check_box">
									<input type="checkbox" name="keep" id="agree1" class="inp_check" v-model="allCheck">
									<label for="agree1" class="lab_check">이용약관, 개인정보처리방침에 모두 동의합니다.</label>
								</div>
							</li>
							<li>
								<div class="check_box">
									<input type="checkbox" name="keep" id="agree2" class="inp_check" v-model="user.isTerms">
									<label for="agree2" class="lab_check">이용약관 동의 <span class="require">(필수)</span></label>
								</div>
								<textarea name="" class="tta" cols="30" rows="5">제 1조 : 목적
와이즈앤퓨어 (이하 '회사'라고 합니다)의 쉽고 편한 중소기업형 빅데이터 플랫폼 서비스 와퓨 서비스(이하 ‘서비스’라고 합니다)를 이용해 주셔서 깊이 감사 드립니다. 본 약관은 '회사'가 제공하는 서비스 이용과 관련하여, 회사와 회원 간 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.

제 2조 : 약관의 명시, 효력
1.본 약관은 서비스 회원 가입 시 본 약관에 동의한 이용자 모두에 대하여 그 효력이 발생하며 회사가 제공하는 서비스 초기 화면 또는 홈페이지에 게시하여 공지합니다.
2.회사는 필요한 경우 관련 법을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다. 본 약관이 변경되는 경우 회사는 변경사항을 시행일자 7일 전부터 이용 고객에게 회사의 고객센터 공지사항에 우선 공지하도록 하며, 이 내용이 고객에게 불리한 내용으로 변경할 경우에는 그 시행일자 30일 전부터 이메일(가입 아이디 기준)을 발송하는 등 가능한 방법으로 변경사항을 공지 또는 통지합니다.
3.고객(이용자)는 개정 약관에 동의하지 않는 경우 적용일자 전일까지 회사에 거부의사를 표시하고 가입을 해지할 수 있습니다

제 3조 : 약관의 해석과 준칙
1.본 약관에 규정되지 않은 사항에 대해서는 관련법령 또는 회사가 정한 서비스의 개별 이용약관, 운영정책 및 규칙 등(이하 ‘세부지침’)의 규정에 따릅니다. 또한 본 약관과 세부지침의 내용이 충돌할 경우 세부지침에 따릅니다.

제 4조 : 와퓨 계정 생성과 이용 계약
1.회원가입은 서비스를 이용하고자 하는 자(이하 "가입신청자"라고 합니다)가 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 하면, 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.

제 5조 : 아이디 생성 제한 및 서비스의 중단
1.회사는 아래와 같은 경우의 가입신청자의 아이디 생성을 승낙하지 않을 수 있습니다.
- 가입 신청자가 본 약관에 의해 서비스 회원 자격을 상실한 적이 있는 경우
- 다른 사람의 명의나 이메일 주소 등의 개인정보를 이용하여 회원 가입을 신청한 경우
- 회사가 요구하는 필수 정보를 입력하지 않거나 허위의 정보를 입력한 경우
- 기타 관련법령에 위배되거나 세부지침 등 회사가 정한 기준에 반하는 경우
- 만 14세 미만 아동의 가입이 확인된 경우
2.만약, 위 내용에 위반하여 계정을 생성한 것으로 판명되는 경우에 회사는 즉시 고객의 서비스 이용을 중단 하거나 계정의 삭제 등 적절한 제한을 할 수 있습니다.
3.회사는 아래의 경우 고객의 계정 생성을 유보할 수 있습니다
- 서비스 제공을 위한 기술적인 부분에 문제가 있거나 설비용량에 여유가 없는 경우
- 회사가 재정적, 기술적으로 보완이 필요하다고 인정하는 경우

제 6조 : 이용자 정보의 보호와 관리
1.회원으로 가입하여 서비스를 이용하고자 하는 개인 이용자는 이메일, 이름 등 정보를 제공하고, 이메일 인증을 진행하여야 합니다. 법인 이용자는 ‘법인의 사업자 등록증(번호)’ 등록을 통해 인증 받아야 합니다.
2.이용자가 제1항에서 정한 인증을 거치지 않은 경우 서비스 이용이 제한될 수 있으며, 본 서비스와 관련된 일체의 권리를 주장할 수 없습니다.
3.회사는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보보호법」 등 관계 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법령 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의 공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.
4.회사는 서비스의 제공과 관련하여 습득한 개인정보 및 계약정보를 본인의 승낙 없이 제 3자에게 누설, 배포하지 않습니다. 다만 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 않을 수 있습니다.
5.이용자의 정보 및 보안내용은 서비스 사용 기간 및 서비스 만료, 해지 이후에도 동일하게 보호됩니다.
6.이용자는 서비스의 이용권한 및 완료 계약서 정보 등에 대해 타인에게 양도나 증여할 수 없습니다.

제 7조 : 책임제한 및 손해배상
1.회사는 천재지변, 디도스(DDOS)공격, IDC장애, 랜섬웨어 공격, 기간통신사업자의 회선 장애, 미디어의 장애 또는 이에 준하는 불가항력적인 상황에 인하여 서비스를 제공할 수 없는 경우 및 이용자의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지 않습니다.
2.회사는 서비스가 변경되거나 중지된 원인이 회사의 고의 또는 과실로 인한 경우를 제외하고는 서비스의 변경 및 중지로 발생하는 문제에 대해서 책임을 지지 않습니다.
3.회사는 서비스 탈퇴 등 이용계약의 종료와 관련하여 발생한 손해는 책임을 지지 않습니다.
4.회사의 귀책사유로 가입자 및 이용자에게 손해를 입힌 경우에는 관계법령에 정한 바 손해배상책임이 있습니다.
5.이용자의 고의 또는 과실로 회사 또는 제3자에게 손해를 발생시킨 경우 손해배상 책임이 있습니다.

제 8조 : 제공 서비스의 이용
1.회사는 무료 서비스 및 유료 서비스를 제공할 수 있으며, 이용자가 회사가 제공하는 서비스를 이 약관, 운영정책 등 회사가 정한 규칙에 따라 이용할 수 있습니다.
2.회사가 이용자에게 제공하는 서비스에 대하여 회사는 제작, 변경, 유지, 보완에 관한 전체적인 권한을 가집니다.
3.회사에서 제공하는 무료 서비스의 경우 회사의 정책이나 운영상 중단, 유료 전환될 수 있으며, 해당 내용에 대해 별도의 보상을 하지는 않습니다.
4.요금제는 정기결제만 가능하며 서비스 해지시 이용금액을 일할 계산(3,300/일)한 차액을 정기결제금액에서 제외하여 환불됩니다. 단 정기결제일 최소 3일전에 신청하여야 합니다.
(예: 서비스를 10일간 이용하고 해지신청했다면 99,000원 - (3,300*10일) = 66,000원을 환불해드립니다)
5.서비스 결제의 경우 신용카드만 가능하며 추후 결제수단이 추가되면 따로 공지합니다.

제 9조 : 이용자의 이용계약 해지
1.이용자가 서비스의 이용을 더 이상 원치 않는 때에는 서비스 내 제공되는 메뉴를 이용하여 해지 신청을 할 수 있으며 회사는 법령이 정하는 바에 따라 신속히 처리 합니다.
2.이용자의 탈퇴 시점 이후 모든 정보는 삭제되며 복구할 수 없습니다.
3.이용자의 회원 탈퇴 시점 이후 5년 이내에 동일한 이메일 주소로 다시 가입할 수 없습니다.
4.유료 이용자의 환불은 이용약관 규정에 따릅니다 (제 8조 4항)

제 10조 : 분쟁의 해결
1.본 약관 또는 서비스는 대한민국법령에 의하여 규정되고 이행됩니다. 서비스 이용과 관련하여 회사와 고객 간에 분쟁이 발생하면 이의 해결을 위해 성실히 협의할 것입니다. 그럼에도 불구하고 서비스 이용과 관련하여 회사와 고객 사이에 분쟁이 발생한 경우에는 회사의 소재지를 관할하는 법원을 제1심 관할법원으로 합니다.
- 공고일자 2023년 1월 1일
- 시행일자 2023년 1월 1일</textarea>
							</li>
							<li>
								<div class="check_box">
									<input type="checkbox" name="keep" id="agree3" class="inp_check" v-model="user.isPrivacy">
									<label for="agree3" class="lab_check">개인정보 수집이용 동의 <span class="require">(필수)</span></label>
								</div>
								<textarea name="" class="tta" cols="30" rows="5">와이즈앤퓨어(이하 '회사'라 합니다)는 와퓨 이용자의 개인정보를 소중히 여기며, '개인정보보호법'등 관련법령을 준수합니다. 회사는 개인정보보호법 제 15조, 17조, 23조, 24조에 따라 회원이 제공하는 개인정보가 어떠한 목적으로 이용되고 있는지 알리고 개인정보 수집·이용에 대한 동의를 받고 있습니다. 회원은 아래의 개인정보의 수집 동의를 거부할 권리가 있으나 동의하지 않으실 경우 관련 업무 처리시 불이익이 있습니다.

[필수항목]
수집·이용 목적 : 와퓨 회원가입 및 관리, 서비스 제공 및 고객 문의 해결
수집하는 개인정보 항목 : 이메일 주소, 이름, 회사명, 핸드폰주소
보유 및 이용기간 : 이용목적 달성시까지 (목적 달성 후 즉시 파기됩니다.)
동의 거부시 불이익 : 서비스 회원가입이 거절될 수 있습니다.

위 필수 항목의 수집 이용에 동의하십니까? □ 예 / □ 아니오</textarea>
							</li>
							<li>
								<p class="ad_tit">마케팅 활용 동의 및 광고 수신 동의</p>
								<p class="ad_desc">서비스와 관련된 소식, 이벤트, 고객 혜택 등 다양한 정보를 제공합니다.</p>
								<div class="check_box">
									<input type="checkbox" name="keep" id="agree4" class="inp_check" v-model="user.isReceiveSms">
									<label for="agree4" class="lab_check">SMS 수신 동의 <span>(선택)</span></label>
								</div>
								<div class="check_box">
									<input type="checkbox" name="keep" id="agree5" class="inp_check" v-model="user.isReceiveEmail">
									<label for="agree5" class="lab_check">E-mail 수신 동의 <span>(선택)</span></label>
								</div>
							</li>
							<li>
								<div class="check_box">
									<input type="checkbox" name="keep" id="agree6" class="inp_check" v-model="user.isOver14">
									<label for="agree6" class="lab_check">만 14세 이상입니다. <span class="require">(필수)</span></label>
								</div>
							</li>
						</ul>
					</div>
					<div class="button_area">
						<button type="button" class="btn_cencle btn_black">취소</button>

<!--						<div class="g_id_signin g_button c-button v&#45;&#45;secondary v&#45;&#45;with-icon v&#45;&#45;google v&#45;&#45;blue u&#45;&#45;border-blue"-->
<!--						     data-clog-click=""-->
<!--						     data-client_id="1007128477735-9lrbddbo4jiu8jbtgdoghg66iaa0bg9h.apps.googleusercontent.com"-->
<!--						     :data-callback="this.handleCredentialResponse"-->
<!--						     data-clog-params="click_target=button_standard"-->
<!--						     data-clog-ui-component="inc_cta_signup"-->
<!--						     data-clog-ui-element="link_sign_in_with_google"-->
<!--						     data-locale="ko_KR"-->
<!--						     data-size="large"-->
<!--						     data-text="signup_with"-->
<!--						     data-theme="filled_blue"-->
<!--						     data-type="standard"-->
<!--						     data-width="205"-->
<!--						     data-label-override="Google로 가입"></div>-->

						<button id="signin_button" type="button" class="btn_join" style="background-color: #1a73e8; padding: 0 5px !important;">Google 가입</button>
					</div>
				</div>
			</div>
		</div>
	</main>

<!--	<div style="height: 500px">-->
<!--		-->
<!--		-->

<!--		<div style="margin-top: 500px" class="button_area">-->
<!--			<h1>회원가입</h1>-->

<!--			<div class="apply_aggre">-->
<!--				<div class="check_box">-->
<!--					<input type="checkbox" name="keep" id="agree1" class="inp_check" v-model="user.isTerms">-->
<!--					<label for="agree1" class="lab_check">이용약관</label>-->
<!--				</div>-->
<!--				<div class="check_box">-->
<!--					<input type="checkbox" name="keep" id="agree2" class="inp_check" v-model="user.isPrivacy">-->
<!--					<label for="agree2" class="lab_check">개인정보처리방침</label>-->
<!--				</div>-->
<!--				<div class="check_box">-->
<!--					<input type="checkbox" name="keep" id="agree3" class="inp_check" v-model="user.isReceiveSms">-->
<!--					<label for="agree3" class="lab_check">sms 수신 동의 여부</label>-->
<!--				</div>-->
<!--				<div class="check_box">-->
<!--					<input type="checkbox" name="keep" id="agree4" class="inp_check" v-model="user.isReceiveEmail">-->
<!--					<label for="agree4" class="lab_check">email 수신 동의 여부</label>-->
<!--				</div>-->
<!--				<div class="check_box">-->
<!--					<input type="checkbox" name="keep" id="agree5" class="inp_check" v-model="user.isOver14">-->
<!--					<label for="agree5" class="lab_check">만 14세 이상 여부</label>-->
<!--				</div>-->
<!--			</div>-->

<!--			<div>-->
<!--				<div id="signin_button"></div>-->
<!--			</div>-->
<!--		</div>-->

<!--	</div>-->
</template>

<script>
export default {
	name: "signUp",
	data() {
		return {
			user: {},
			allCheck: false,
			btnOpen: false,
		}
	},
	created() {
		let googleScript = document.createElement('script');
		googleScript.src = 'https://accounts.google.com/gsi/client';
		document.head.appendChild(googleScript);
		},
	mounted() {


		window.addEventListener('load', () => {
			console.log(window.google);
			window.google.accounts.id.initialize({
				client_id: "1007128477735-9lrbddbo4jiu8jbtgdoghg66iaa0bg9h.apps.googleusercontent.com",
				callback: this.handleCredentialResponse
			});
			window.google.accounts.id.renderButton(
					document.getElementById("signin_button"),
					{theme: "filled_blue", type:'standard'}  // customization attributes
			);
		})

		this.user.isOver14 = false
		this.user.isPrivacy = false
		this.user.isReceiveEmail = false
		this.user.isReceiveSms = false
		this.user.isPrivacy = false
	},
	methods: {

		handleCredentialResponse(response) {
			if( !this.user.isOver14 || !this.user.isPrivacy || !this.user.isOver14 ) {
				this.swalUtils.gritter("필수 동의 항목에 체크해주세요", "", "info", 3000)
			} else {
				let responsePayload = response.credential.split('.')[1];
				let payload = Buffer.from(responsePayload, 'base64');
				let result = JSON.parse(payload.toString())

				//유저가 존재하는지 체크
				this.findByUser(result);
			}
		},

		findByUser(result) {
			const self = this;
			this.axiosCaller.post(this, this.APIs.USER + "/isUser", {email: result.email}, (res) => {
				var data = res.data;
				if (data.status == "ok") {
					if (data.userVO != null) {
						if (data.userVO.isUse === true) {
							this.swalUtils.gritter("이미 가입된 계정입니다", "", "info", 3000)
						} else {
							this.swalUtils.gritter("탈퇴된 계정입니다.", "관리자에게 문의바랍니다.", "info", 3000)
						}
					} else {
						// 회원가입 처리
						self.user.userName = result.name;
						self.user.emailId = result.email;

						self.doSignUp();
					}
				} else {
					console.log(data.msg)
				}
			})
		},

		doSignUp() {
			const self = this;
			console.log('::::::::::userVO', this.user)
			this.axiosCaller.post(this, this.APIs.USER + "/doSignUp", this.user, async (res) => {
				var data = res.data;
				if (data.status == "ok") {
					// 로그인 처리 ㅇㅇ
					self.swalUtils.gritter("회원가입이 완료되었습니다.", "", "success", 3000)
							.then(async () => {
								location.href = "/"
								// if (await self.setSessionProc(data.userVO)) {
								// 	self.setSessionKeep(self.isKeepSession);
								// 	if (!self.stringUtils.isEmpty(self.$route.query.referrer)) {
								// 		console.log(self.$route.query.referrer)
								// 		location.href = self.$route.query.referrer
								// 	} else {
								// 		location.href = "/"
								// 	}
								// }
							});
				} else {
					this.swalUtils.fire("FAIL", data.msg, "info", null, null)
					console.log(data)
				}
			})
		}

	},
	watch: {
		'allCheck': function (newVal) {
			if(newVal) {
				this.user.isOver14 = true
				this.user.isPrivacy = true
				this.user.isReceiveEmail = true
				this.user.isReceiveSms = true
				this.user.isTerms = true
			} else {
				this.user.isOver14 = false
				this.user.isPrivacy = false
				this.user.isReceiveEmail = false
				this.user.isReceiveSms = false
				this.user.isTerms = false
			}
		},
	}
}
</script>

<style scoped>

</style>